import {
  defer,
  type LinksFunction,
  type MetaFunction,
  type LoaderArgs,
  type AppLoadContext } from
'@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  LiveReload,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  type ShouldRevalidateFunction } from
'@remix-run/react';
import { ShopifySalesChannel, Seo } from '@shopify/hydrogen';
import NextTopLoader from 'nextjs-toploader';
import { jwtDecode } from 'jwt-decode';

import { Layout } from '~/modules';
import { GenericError } from './modules/Common/components/GenericError';
import styles from './styles/app.css';
import { DEFAULT_LOCALE } from './lib/utils';
import invariant from 'tiny-invariant';
import { Shop, Cart } from '@shopify/hydrogen/storefront-api-types';
import { useAnalytics } from './modules/Common/hooks/useAnalytics';
import { getExpertThemeCSS } from './modules/Expert/util';
import {
  getExpertDomain,
  getExpertSubdomain,
  getLoaderExpertName,
  getPublicEnv,
  isFeatureVisible } from
'./modules/Common/util';
import { GoogleFonts } from './modules/Common/types';
import { useExpert } from './modules/Common/hooks/useExpert';
import { SEOExtended } from './modules/Common/components/SEOExtended';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { getExpertApiTheme, getExpertNav } from './modules/Expert/utils.server';

import { AnalyticsProvider } from './modules/Common/AnalyticsProvider';
import { getRootUserData } from './modules/Common/root.utils.server';
import { DataDogRUM } from './modules/Common/components/Datadog';
import { getCart } from './modules/Cart/utils.server';
import { ClientOnly } from 'remix-utils';
import { Hotjar } from './modules/Common/components/Hotjar';
import { ThemeProvider } from './modules/Bio/context/ThemeContext';
import { getSession } from '~/lib/auth.server';
declare global {
  interface Window {
    ENV: {
      CONTENT_API_ENDPOINT: string;
      CONTENT_API_KEY: string;
      PUBLIC_ENV: string;
      PUBLIC_STOREFRONT_API_VERSION: string;
      PUBLIC_STORE_DOMAIN: string;
      PUBLIC_STOREFRONT_API_TOKEN: string;
      PUBLIC_RUDDERSTACK_WRITE_KEY: string;
      PUBLIC_RUDDERSTACK_DATA_PLANE_URL: string;
      PUBLIC_CUBE_API_URL: string;
    };
  }
}

export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: styles },
  {
    rel: 'preconnect',
    href: 'https://cdn.shopify.com'
  }];

};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1, viewport-fit=cover'
});

export const shouldRevalidate: ShouldRevalidateFunction = ({
  formMethod,
  currentUrl,
  nextUrl
}) => {
  // revalidate when a mutation is performed e.g add to cart, login...
  if (formMethod && formMethod !== 'GET') {
    return true;
  }

  // revalidate when manually revalidating via useRevalidator
  if (currentUrl.toString() === nextUrl.toString()) {
    return true;
  }

  return false;
};

export async function loader({ context, request, params }: LoaderArgs) {
  const expertName = getLoaderExpertName(params, request);
  const [cartId, layout, { theme, socialMedia }, expertNav, userData] =
  await Promise.all([
  context.session.get('cartId'),
  getLayoutData(context),
  getExpertApiTheme(params, request, context.client),
  getExpertNav(expertName, context.client),
  getRootUserData({ request, context, client: context.client })]
  );

  const subdomain = getExpertSubdomain(request);
  const session = await getSession(request.headers.get('Cookie'));
  let isAdmin = false;
  if (session?.data?.user?.token) {
    const decodeToken: {
      'https://wayward.com/roles': string;
    } = jwtDecode(session?.data?.user?.token);
    const adminRole = decodeToken['https://wayward.com/roles'];
    isAdmin = adminRole?.length ?
    adminRole[0]?.toUpperCase() === 'ADMIN' :
    false;
  }
  return defer({
    font: theme.font,
    subdomain,
    domain: getExpertDomain(request),
    layout,
    selectedLocale: context.storefront.i18n,
    cartData: cartId ? getCart(context, cartId, expertName) : undefined,
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.hydrogen,
      shopId: layout.shop.id
      //  domain: process.env.PUBLIC_STORE_DOMAIN,
    },
    ENV: getPublicEnv(context.env),
    theme,
    socialMedia,
    expertNav,
    user: context.user,
    userData,
    isAgencyVisibleRootUser: isFeatureVisible((
      userData.brand?.id as string),
      context.env.PUBLIC_ENV
    ),
    isAdmin: !isAdmin
  });
}

export default function App() {
  return (
    <ThemeProvider>
      <AppLayout />
    </ThemeProvider>);

}

const AppLayout = () => {
  const data = useLoaderData<typeof loader>();
  const locale = data.selectedLocale ?? DEFAULT_LOCALE;
  const { theme, store } = useExpert();
  const headlineFont =
  theme?.v2?.headlineFont || theme?.font?.headline || 'Open Sans';
  const bodyFont = theme?.v2?.bodyFont || theme?.font?.body || 'Open Sans';
  const bioFont = theme?.bio?.font || '';

  return (
    <html lang={locale.language}>
      <head>
        <Seo />
        <SEOExtended />
        <Meta />
        <Links />
        {store?.favicon &&
        <link rel="icon" type="image" href={store.favicon} />}

        {!store?.favicon &&
        <>
            <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicons/apple-touch-icon.png" />

            <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/favicons/apple-touch-icon-120x120.png" />

            <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicons/favicon-16x16.png" />

            <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicons/favicon-32x32.png" />

            <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/favicons/android-chrome-192x192.png" />

            <link
            rel="icon"
            type="image/png"
            sizes="256x256"
            href="/favicons/android-chrome-256x256.png" />

            <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" />
            <link rel="shortcut icon" href="/favicon.ico" />
          </>}

        {Object.keys(GoogleFonts).includes(headlineFont) &&
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css?family=${headlineFont}:400,500,700&display=swap`} />}


        {headlineFont !== bodyFont &&
        Object.keys(GoogleFonts).includes(bodyFont) &&
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css?family=${bodyFont}:400,500,700&display=swap`} />}


        {Object.keys(GoogleFonts).includes(bioFont) &&
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css?family=${bioFont}:400,500,700&display=swap`} />}


        <style
          dangerouslySetInnerHTML={{
            __html: `
          :root {
            --expert-font-headline: '${headlineFont || 'Inter'}';
            --expert-font-body: '${bodyFont || 'Inter'}';
          }
        `
          }} />

        <style dangerouslySetInnerHTML={{ __html: getExpertThemeCSS(theme) }} />
        <ClientOnly>{() => <Hotjar />}</ClientOnly>
      </head>
      <body style={{ minHeight: '-webkit-fill-available' }}>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(getPublicEnv(data.ENV))}`
          }} />

        <AnalyticsProvider>
          <RootLayout />
        </AnalyticsProvider>
        <DataDogRUM env={data.ENV.PUBLIC_ENV} />
        <Scripts />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.5/flowbite.min.js" />
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </body>
    </html>);

};

const RootLayout = () => {
  const data = useLoaderData<typeof loader>();
  const locale = data.selectedLocale ?? DEFAULT_LOCALE;
  useAnalytics(true, locale);
  const [queryClient] = useState(new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <Outlet />
      <ScrollRestoration />
      <LiveReload />
      <NextTopLoader color="#5f1dba" />
    </QueryClientProvider>);

};

export function ErrorBoundary({ error }: {error: Error;}) {
  const [root] = useMatches();
  const locale = root?.data?.selectedLocale ?? DEFAULT_LOCALE;

  return (
    <html lang={locale.language}>
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout>
          <GenericError error={error} />
        </Layout>
        <Scripts />
      </body>
    </html>);

}

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
    }
  }
`;

export interface LayoutData {
  shop: Shop;
  cart?: Promise<Cart>;
}

async function getLayoutData({ storefront }: AppLoadContext) {
  let data;
  try {
    data = await storefront.query<LayoutData>(LAYOUT_QUERY, {
      variables: {
        language: storefront.i18n.language
      }
    });
  } catch (e) {
    console.error(e);
  }

  invariant(data, 'No data returned from Shopify API');

  return { shop: data.shop };
}